import Vue from 'vue';
import windowInfo from './windowInfo.vue'
let infoContent = Vue.extend(windowInfo)
import store from '@/store'
function isFunction(val) {
    return typeof (val) === "function";
}
export default function(data){
    console.log(data)
    let instance = new infoContent({store,propsData:{
      animeId:data.animeId,
      pointInfo:data.pointInfo
    }}).$mount()
    let vm = this
    instance.onParam = (paraList,positionInfo) => {
      if(vm){
        if(isFunction(vm.$options.onParam)) vm.$options.onParam.call(vm,paraList,positionInfo)  
      }   
    }
    instance.onClose = (data) => {
    
      if(vm){
    
        if(isFunction(vm.$options.onClose))vm.$options.onClose.call(vm,data)
        
      }   
    }

    // instance.$props.animeId = data.animeId   
    // instance.$props.pointInfo = data.pointInfo
     
    return instance.$el
}
<template>
  <ul class="site-menu wh100 boxScroll">
    <li v-for="item in menuList" :key="item.id">
      <div @click="clickMenu(item)" class="menuName boxBg cp">
    
        <div class="sinleline">{{ item.fenceName }} <span class="baseColor">{{ `(${item.almInfos.length})`}}</span></div>
        <div>
          <template v-if="isSlot">
            <slot name="rightTools"></slot>
          </template>
          <template v-else>
            <i :class="item.isSort ? 'iconfont icon-paixu-jiangxu':'iconfont icon-quyu'" :style="rotateStlye(item.isSort)" @click.stop="updateArea(item)" v-if="isEdit"></i>
          </template>
        </div>
      </div>
      <collapse-transition>
        <ul class="site-menu-sub" v-show="item.almInfos && isOpen === item.id">
          <li v-for="(child, index) in item.almInfos" :key="index" @click="clickSubMenu(child)" :class="['site-menu-item', 'cp',
                    isActiveMenu(child) ? 'menu-active-bg-image':'menuItemBg']">
            <span :class="['iconfont', 'icon-tongshe-erbiao',isActiveMenu(child)? 'baseColor':'']"></span>
            <div :class="[isActiveMenu(child)? 'baseColor':'']">
              <p class="sinleline">{{child.earCode || child.animaId}}</p>
              <!-- <p :class="[isActiveMenu(child)? 'baseColor':'']">{{child.devCode}}</p> -->
            </div>
          

          </li>
        </ul>
      </collapse-transition>
    </li>
  </ul>
</template>

<script>
import CollapseTransition from '@/libs/collapse-transition';
import config from '@/config'
import { calculateCenter } from  '@/libs/util.js'
export default {
  components: {
    'collapse-transition': CollapseTransition,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true
    },
    isSlot: {
      type: Boolean,
      default: false
    },
    menus: {
      type: Array,
      default: () => []
    },
    // defaultOpenMenu:,
    groupState: {
        type: Boolean,
        default: false 
    },
    activeInfo: {
      type: String,
      
    }
  },
  watch: {
    activeInfo: {
      handler () {
        this.setMenuOpen()
      }
    },
    menus: {
      handler (val) {
       
        this.menuList = this.addSrotAttr()
        if (this.srotType) {
          this.srotType = false
          return
        }
        this.setMenuOpen()
      },
      deep: true,
      immediate: true
    },
    // activeMenu: {
    //   handler (val) {
        
    //     if(!val) return 
        
    //     let data = [val,this.menus]
    //     this.$emit('change', data)
    //   },
    //   deep: true
    // },
    // defaultOpenMenu: {
    //   handler (val) {
    //     this.setMenuOpen()
    //   },
    //   // immediate: true
    // }
  },
  data () {
    return {
      isOpen: '',
      activeMenu: '',
      menuList: [],
      sortIcon: ['icon-paixu-jiangxu', 'icon-paixu-shengxu'],
      srotType: false,
      onlineData: config.devStatus.online,
      isInit: true
    }
  },
  created () {
    // 考虑异步问题，下午监听 menus 解决
    // this.setMenuOpen() // 设置默认开启项
    // this.menuList = this.addSrotAttr()
  },
  destroyed(){
   
    this.clearLocaInfo()
  },
  computed: {
   
    isActiveMenu () {
      return dev => {
        if (this.activeMenu == dev.animaId) return true
        else return false
      }
    },
    rotateStlye () {
      return isSort => isSort ?
        { transition: 'all 0.3s ease 0s', transform: 'rotate(0.5turn)' } :
        { transition: 'all 0.3s ease 0s', transform: 'rotate(0turn)' }
    }
  },
  methods: {
    // 获取本地激活项
    getActiveId(){
      return this.activeInfo || localStorage.getItem('activeAnimelMenu')
    },
    clearLocaInfo(){
    
        localStorage.removeItem('activeAnimelMenu')
    },
    clickMenu (i) {

      if (this.isOpen === i.id) {
        this.isOpen = '';
      } else {
        this.isOpen = i.id;
      }

    },
    clickSubMenu (dev) {
      localStorage.setItem('activeAnimelMenu',JSON.stringify(dev.animaId))
      this.activeMenu = dev.animaId
     
      this.$emit('change', [this.activeMenu,this.menus])
 
    },
    
    setMenuOpen () {
     
         let activeId = this.getActiveId()
         if(!activeId && this.menus && this.menus.length){
              
              this.isOpen = this.menus[0].id
              let defaultPath = this.menus[0].rangeLatitude
     
              let centerPosition = calculateCenter(JSON.parse(defaultPath))
             
              this.$emit('setCenter',centerPosition)
              // this.$nextTick(()=> {
              //   this.activeMenu = this.menus[0].almInfos[0].animaId
              //   console.log(this.activeMenu)
              // })
         }else{
           let falg = false
           this.menus.some(item => {
                if(item.almInfos && item.almInfos.length){
                   let isOk = item.almInfos.some(sub => {
                       if(this.activeId == sub.animaId){
                         this.isOpen = sub.fenceId
                         falg = true
                         this.$nextTick(()=> {
                          this.activeMenu = sub.animaId
                          this.$emit('change', [this.activeMenu,this.menus])
                        })
                         return true
                       }
                   })
                   return isOk
                }
            })
            if(!falg && this.menus && this.menus.length){
              this.isOpen = this.menus[0].id
              let defaultPath = this.menus[0].rangeLatitude
              let centerPosition = calculateCenter(JSON.parse(defaultPath))
              this.$emit('setCenter',centerPosition)
              // this.$nextTick(()=> {this.activeMenu = this.menus[0].almInfos[0].animaId})
            }
         }
        
    },
    updateArea(item) {
      let defaultPath = item.rangeLatitude
      let centerPosition = calculateCenter(JSON.parse(defaultPath))
      this.$emit('setCenter',centerPosition)
    },
    addSrotAttr () {
      this.menus.forEach(item => {
        if (!item.hasOwnProperty('isSort')) item.isSort = false
      })
      return this.menus
    },
    srotChange (data) {
      if (data.almInfos && data.almInfos.length > 1) {
        data.almInfos.reverse()
        data.isSort = !data.isSort
        this.srotType = true
        if (this.isOpen !== data.id) {
          this.isOpen = data.id
        } else {
          this.isOpen = ''
          setTimeout(() => {
            this.isOpen = data.id
          }, 500);
        }
      } else {
        // this.$msg.info('设备数量 < 2,无法排序！')
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.site-menu {
  font-size: 14px;
  color: #fff;
  padding-right: 5px;
  & > li {
    .menuName {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      & > div:first-child {
        flex: 1;
        text-align: center;
        margin-left: 10px;
        max-width: 240px;
      }
      & i {
        color: #dcdfe6;
        font-size: 18px;
      }
    }
    .site-menu-item {
      width: 100%;
      height: 40px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      // justify-content: center;
      & > span {
        display: block;
      
        flex-shrink: 0;
      }
      & > span:first-child {
        width: 30px;
        font-size: 20px;
        margin: 0 0 0 20px;
      }
      & > span:last-child {
        font-size: 18px;
        width: 24px;
        margin: 0 8px;
      }
      & > div {
        // flex: 1;
        min-width: 0;
        // max-width: 170px;
        & > p:last-child {
          width: 100%;
          color: #dcdfe6;
          font-size: 12px;
        }
      }
    }
   
  }
  & > li:not(:first-child) .menuName {
    margin-top: 6px;
  }
  & > li:first-child .menuName {
    border-radius: 8px 8px 0 0 !important;
  }
  & > li:last-child .menuName {
    border-radius: 0 0 8px 8px;
  }
}
</style>
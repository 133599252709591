<template>
    <div class="navSearch_main_box wh100">
       
            <div class="search_box pr">
                    <el-autocomplete
                        class="inline-input"
                        v-model="searchVal"
                        clearable
                        ref="cautocomplete"
                        :fetch-suggestions="querySearch"
                        placeholder="请输入耳标号查询"
                        :trigger-on-focus="false"
                        @select="handleSearch"
                        @clear="clearSearch"
                    >
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    <template slot-scope="{ item }">
                          <div class="auto_select_box">
                              <span :class="['iconfont', 'icon-tongshe-erbiao', 'baseColor','autoIcon']"></span>
                              <div class="info_box">
                                  <p>{{ item.iotDevCode || item.animaId}}</p>
                              </div>
                              <!-- <p>无数据</p> -->
                          </div>
                      </template>
                    </el-autocomplete>
                    <!-- <el-tooltip effect="dark" :content="groupContent" placement="top">
                         <span :class="['active-cp', 'grounpIcon', 'iconfont', groupIcon]" @click="groupChange"></span>        
                    </el-tooltip> -->
                    
            </div>
        <template v-if="navMenus.length">
            <!--     :groupState='groupState' -->
            <Menu class="menu_box" 
            v-bind="$attrs" 
            v-on="$listeners" 
            :activeInfo='activeInfo'
            :menus="navMenus"></Menu>  
       </template>
       <template v-else>
           <div class="mt100">
                <hn-emptyData :text= "emptyText"  size= '160px' setType= 'col'></hn-emptyData>
           </div>
             
       </template>
       
      
    </div>
</template>

<script>
    import Menu from './Menu.vue'
    import config from '@/config'
    import { deepClone } from '@/libs/util.js'
    export default {
        name: 'HnMenu',
        components:{
            Menu,
        },
        props:{
        
          baseId: String

        },
        data(){
            return{
               searchVal: '',
               navMenus: [],
               copyMenus: [],
               restaurants: [], // 搜索信息保存值，包含父级信息  
               activeInfo: null, // 激活菜单项 
               activeOpenMenu: [],      
               reqData:{
                 areaId: '',
                 iotDevCode: '',
                 id: ''
               }     
            }
        },
        watch:{
           baseId:{
              handler(val,oldVal){
                 const areaId = val && val !== 'all' ? val : ''
                 this.reqData.id = areaId
                 this.reqMenu().then(res => this.$emit('onReq',res))
              },
              immediate:true 
           } 
        },
        computed: {
            
            // groupContent() {
            //    return this.groupState ? '基地菜单' : '分组菜单'
            // },
            emptyText() {
               return '暂无数据'
            },
            // groupIcon() {
            //    return this.groupState ? 'icon-tongwu-jidi1' : 'icon-tongwu-fenzu' 
            // },
            // defaultApi() {
            //     return this.groupState ? 'BaseDeviceList' : 'GroupDeviceList'
            // },
        
        },
        methods:{
            async reqMenu() {
             try{
               const res = await this.$api.TraFenceInfo(this.reqData)
               if(res){
                   this.navMenus = res
                   this.copyMenus = res  // 用于搜索还原
                   return res
                } 
             }catch{
                 return false
             }  
               
            },
            querySearch(queryString, cb) {
                
                var restaurants = []
                if(this.navMenus.length){
                     this.navMenus.forEach(item => {
                          if(item.almInfos && item.almInfos.length){
                            item.almInfos.forEach(val => {
                             
                                // value 和 Id 为搜索组件绑定值，key不能更改
                                restaurants.push(val)
                            })
                          }
                     })
                }
                this.restaurants = restaurants
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
              
                cb(results);
            },
            createFilter(queryString) {
                return (restaurant) => {
                    // 这里可以通过value 和 id一起判断
                  return restaurant.iotDevCode ?.toLowerCase() ?.indexOf(queryString.toLowerCase()) > -1 
                         
                };
            },
            handleSearch(data) {
                
                let arrList = []
                this.navMenus.some(item => {
                    if(item.id == data.fenceId){
                        let copyItem = deepClone(item)
                        this.activeInfo = data.animaId
                        copyItem.almInfos =  this.todo(copyItem.almInfos,data.animaId)
                      
                        arrList.push(copyItem)
                        return true
                    } 
                })
                
                this.navMenus =  arrList

            },
            clearSearch() {
                this.$refs.cautocomplete.activated = true
                this.activeInfo = null
                this.navMenus = this.copyMenus
            },
            todo(arr,id) {
                let index = 1,newArr = [], length = arr.length;
                for(var i = 0; i < length; i++){
                    if(arr[i].animaId == id){
                        newArr[0] = arr[i]
                    }else{
                        newArr[index++] = arr[i]
                    }
                }
              
                return newArr

            }
        }
    }
</script>

<style lang="scss" scoped>
 .navSearch_main_box{
    .search_box{
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #DCDFE6;
        margin-bottom: 10px;
        .grounpIcon{
            position: absolute;
            right: 0;
            top: 9px;
            font-size: 20px;
        }
    }
    .menu_box{
        height: calc(100% - 50px);
    }
    .el-autocomplete{
        width: 100%;
    }
    .el-autocomplete ::v-deep .el-input__inner{
        background: none;
        border: none;
        color: #fff;
    }
    .mt100{
        margin-top: 100px;
    }
    .inline-input{
        width: 255px;
    }
  
 }
</style>
<template>
  <div class="electronic_fence_box">
    <div class="top_row_box">
      <div class="left_box">
        <el-select class="mr-20" v-model="baseId" size="small" style="width: 260px; marginright: 30px" @visible-change="animalBaseChange" placeholder="请选择基地">
          <el-option v-for="item in baseOption" :key="item.baseId" :label="item.baseName" :value="item.baseId"> </el-option>
        </el-select>
        <div class="mr-60" v-for="(item, i) in animalInfo" :key="'anime' + i">
          <span class="mr-10">{{ item.name }}：{{ item.sum }}头</span>
          <span class="mr-10">正常：{{ item.sum - item.abnormal }}头</span>
          <span class="mr-10">异常：{{ item.abnormal }}头</span>
          <span>佩戴定位设备{{ item.bonNum }}头</span>
        </div>
      </div>
      <div class="right_box">
        <span class="font-14 mr-10">推送方式</span>
        <sendType></sendType>
      </div>
    </div>
    <div class="fence_content_box">
      <div class="meun_search_box">
        <areaMenu v-if="map" @change="menuChange" :baseId="baseId" @onReq="toBaseChange" @setCenter="setCenter"></areaMenu>
      </div>
      <div class="map_box">
        <div id="container" style="width: 100%; height: 100%"></div>
        <div class="open_iocn_box" @click="toOpenWarn">
          <span :class="['iconfont', !isOpen ? 'icon-gaojing1' : 'icon-jiantou_xiangyou']"></span>
        </div>
      </div>
      <div :class="['warn_box', 'boxBg', { colse_warn: !isOpen }]">
        <div class="warn_top_row">
          <div class="warn_title_box">
            <span class="iconfont icon-gaojing1"></span>
            <span>告警信息</span>
          </div>
          <!-- <span class="iconfont icon-shuaxin update_icon" @click="getTraWarningInfoPageList(true)"></span> -->
        </div>
        <div class="warn_menu">
            <div v-for="item in $dictionary('warningStatus')" @click="toWarnChange(item.value)" :key="item.value" :class="[activeWarn == item.value ? 'active_warn':'']">{{ item.label }}</div>
        </div>
        <ul v-infinite-scroll="warmListload" class="warn_list" v-loading="changLoading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(21, 35, 45, 0.8)">
          <li class="warn_item" v-for="item in warnList" :key="item.id">
            <span class="point" v-if="item.warningStatus == 2"></span>
            <div class="info_box">
              <p class="row_flex_box">
                <span>耳标号：</span>
                <span>{{ item.earCode }}</span>
              </p>
              <p class="row_flex_box">
                <span>所属围栏：</span>
                <span>{{ item.fenceName }}</span>
              </p>
              <p class="row_flex_box">
                <span>所属基地：</span>
                <span>{{ item.baseName }}</span>
              </p>
              <p class="row_info_box">
                <span>告警内容：</span>
                <span class="warnc font-12" style="width: 120px;word-break: break-all">{{ item.warningContent }}</span>
              </p>
              <div class="time_box">{{ item.created }}</div>
              <div :class="['but_box',activeWarn == 1 ? 'floatRight':'']">
                <div class="but active_but cp" @click="toPostion(item.animaId)">定位追踪</div>
                <div class="but cp" @click="toRead(item)" v-if="activeWarn == 2">标记处理</div>
              </div>
            </div>
          </li>
          <hn-empty-data setType="col" text="暂无告警数据" v-if="!warnList || !warnList.length"></hn-empty-data>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import AMapLoader from "@amap/amap-jsapi-loader";
  import transform from "@/libs/transformMap.js";
  import windowInfo from "./components/windowInfo";
  import areaWindowInfo from "./components/areaWindowInfo";
  import sendType from "./components/sendType.vue";
  import { imgAddHost } from "@/libs/util";
  import areaMenu from './components/areaMenu'
  export default {
    components: { sendType,areaMenu },
    data() {
      return {
        baseOption: [],
        sendOption: [],
        animalInfo: [],
        baseId: "all",
        sendType: "",
        isOpen: true,
        map: null,
        infoWindow: null,
        pathSimplifierIns: null,
        warnList: [],
        pageIndex: 1,
        warnInfoNub: 0,
        AMap: null,
        markers: [],
        polygons: [],
        simpInfo: null,
        activeWarn: 1,
        changLoading: false,
        delPositionMarker: null,
        positionMarker: null
      };
    },
    created() {
      this.$nextTick(() => {
        this.mapInit();
      });
      this.getEntBaseAreaInfo();
      this.getTraWarningInfoPageList();
    },
    onClose() {
      this.infoWindow.close();
    },
    onParam(info) {
      this.setPathSimp(info);
    },
    computed: {
      userInfo() {
        return this.$store.state.user.userInfo;
      },
      isMapLayer() {
        return this.$store.state.user.userInfo?.mapLayer == 0 ? true : false;
      },
      isScollLoad() {
        return this.warnList.length < this.warnInfoNub ? true : false;
      },
      defaultAnimalIcon(){
         return require(`@/assets/imgs/fence/${this.$themeCode}/ani.png`)
      },
      delIcon(){
 
         return require(`@/assets/imgs/fence/${this.$themeCode}/del.png`)
      },
      arrowIcon(){
     
         return require(`@/assets/imgs/fence/${this.$themeCode}/arrow.png`)
      },
      positionIcon(){
        return require(`@/assets/imgs/fence/${this.$themeCode}/anip.png`)
      }
      
    },
    methods: {
      // 更新中心点位
      setCenter(point){
        
          if(this.map) this.map.setCenter(point)
      },
      toBaseChange(list){
         // 设置区域信息
         
         this.setPolygon(list);
         let pointList = []
         list.some(item => {
              if(item.almInfos && item.almInfos.length){
                  item.almInfos.some(sub => {
                      if(sub.currPosition){
                         pointList.push(sub)
                      }
                  })
                
              }
         })
         this.setMarket(pointList);
      },
      toWarnChange(code){
        this.activeWarn = code
        this.getTraWarningInfoPageList(true)
      },
      menuChange(data){
      
        let [val,list] = data
        // 设置动物点位信息
        list.some(item => {
            if(item.almInfos && item.almInfos.length){
                item.almInfos.some(sub => {
                    if(val == sub.animaId){
                    
                      if(sub.currPosition){
                         let lngLat = sub.currPosition.split(",")
                         lngLat = transform.gTw(lngLat[0], lngLat[1])
                         this.setCenter(lngLat)
                         this.markers.map(marker => {
                         
                            if(marker.animaId == sub.animaId){
                             
                              const $dome = windowInfo.call(this, {
                                animeId: marker.animaId,
                                pointInfo: marker.dataInfo,
                              });

                              //鼠标点击marker弹出自定义的信息窗体
                              let infoWindow = new AMap.InfoWindow({
                                isCustom: true, //使用自定义窗体  windowInfo(e.target.orderno.deviceDataInfos)
                                content: $dome,
                                closeWhenClickMap: false,
                                anchor: new AMap.Pixel(0, 0),
                                offset: new AMap.Pixel(50, -80),
                              });

                              infoWindow.open(this.map, lngLat);

                              this.infoWindow = infoWindow;
                            }
                         })
                         
                      }else{
                         this.$msg.info(`[${sub.iotDevCode || sub.animaId}]-动物暂无定位信息`)  
                      }
                      return true
                    }
                })
              
            }
        })
       
      },
      warmListload() {
        if (this.isScollLoad) {
          this.pageIndex++;
          this.getTraWarningInfoPageList();
        }
      },
      toOpenWarn() {
        this.isOpen = !this.isOpen;
      },
      animalBaseChange() {
        // this.getTraBaseAreaAnimalList();
        // this.getTraFenceDetailed();
        this.getTraBaseAnimalInfo();
      },
      mapInit(lngLat) {
        window.forceWebGL = true;
        AMapLoader.load({
          key: "296001efb793b1e2631010fa1e8c0524", // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ["AMap.GltfLoader", "AMap.MoveAnimation"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
          Loca: {
            // 是否加载 Loca， 缺省不加载
            version: "2.0", // Loca 版本，缺省 1.3.2
          },
          // AMapUI: {
          //   version: '1.1',
          //   plugins:['overlay/SimpleMarker']
          // },
        })
          .then((AMap) => {
            this.global_websocket_connect();
            this.global_websocket_receive_message(
              "/topic/publishanimaltagdevicedata." + this.$companyId,
              (res) => {
                let dataInfo = JSON.parse(res.body);
                this.markers.some((marker) => {
                  if (marker.animaId == dataInfo.animaId) {
                    marker.setPosition(transform.gTw(dataInfo.lng, dataInfo.lat));
                    return;
                  }
                });
              },
              false
            );
            this.AMap = AMap;
            lngLat = this.userInfo ? transform.gTw(this.userInfo.lng, this.userInfo.lat) : lngLat;
            // lngLat = transform.gTw(100.990626, 37.083374);
            let map = new AMap.Map("container", {
              zoom: this.userInfo.mapLevel || 16,
              pitch: 40,
              viewMode: '3D',
              center: lngLat,
              // zoom: 15.5,
              // center: lngLat,
            });
            this.map = map;
            map.on("click", (e) => {
              console.log(e);
            });
            if (!lngLat) this.getIpPosition(AMap);

            if (this.isMapLayer) {
              let wms = new AMap.TileLayer.WMTS({
                url: "http://t4.tianditu.gov.cn/img_w/wmts",
                blend: false,
                titleSize: 256,
                params: {
                  Layer: "img",
                  Version: "1.0.0",
                  Format: "tiles",
                  TileMatrixSet: "w",
                  STYLE: "default",
                  tk: "5f736f7a2df74e65321db96c568a7e16",
                },
              });
              wms.setMap(map);
            }

            // this.getTraBaseAreaAnimalList();
            // this.getTraFenceDetailed();

            //
          })
          .catch((e) => {});
      },
      // 设置market
      setMarket(animeList) {
        let AMap = this.AMap;
        if (this.markers.length) {
          this.map.remove(this.markers);
          this.markers = [];
        }
        animeList.map((item) => {
          if (!item.currPosition) return;
          

          let [lng, lat] = item.currPosition.split(",");
          let animeIcon = item.agrImage ? imgAddHost(item.agrImage) : this.defaultAnimalIcon;
         
          // 创建一个 Icon
          var startIcon = new AMap.Icon({
            // 图标尺寸
            size: new AMap.Size(48, 48),
            // 图标的取图地址
            image: animeIcon,
            // 图标所用图片大小
            imageSize: new AMap.Size(48, 48),
          });
          var marker = new AMap.Marker({ zIndex: 120 });
          // [item.lng,item.lat] transform.gTw(104.063321,30.594767) transform.gTw(item.lng,item.lat)

          marker.setPosition(transform.gTw(lng, lat));
          marker.setMap(this.map);
          marker.setIcon(startIcon);
          marker.animaId = item.animaId;
          marker.dataInfo = item;
          this.markers.push(marker);
          let _that = this;
          marker.on("click", (e) => {

            let point = this.isPoint(e);
            this.map.setCenter(point);
            const $dome = windowInfo.call(_that, {
              animeId: item.animaId,
              pointInfo: item,
            });

            //鼠标点击marker弹出自定义的信息窗体
            let infoWindow = new AMap.InfoWindow({
              isCustom: true, //使用自定义窗体  windowInfo(e.target.orderno.deviceDataInfos)
              content: $dome,
              closeWhenClickMap: false,
              anchor: new AMap.Pixel(0, 0),
              offset: new AMap.Pixel(50, -50),
            });

            infoWindow.open(this.map, point);

            _that.infoWindow = infoWindow;
          });
        });
      },
      isPoint(e) {
        const allWidth = document.body.clientWidth;
        let times = 1920 / allWidth;
        let pixelarr = e.pixel.toArray();

        var pixel = new this.AMap.Pixel(pixelarr[0] * times, pixelarr[1] * times);
        var lnglat = this.map.containerToLngLat(pixel);

        if (lnglat) return lnglat;
      },
      // 设置polygon
      setPolygon(areaList) {
        let AMap = this.AMap;

        if (this.polygons.length) {
          this.map.remove(this.polygons);
          this.polygons = [];
        }
        areaList.map((item) => {
          let polygon = new AMap.Polygon({
            strokeColor: "#FFF",
            strokeOpacity: 1,
            strokeWeight: 2,
            strokeOpacity: 0.4,
            fillColor: item.mapColor,
            fillOpacity: 0.4,
            strokeStyle: "solid",
            map: this.map,
            path: JSON.parse(item.rangeLatitude),
          });
          this.polygons.push(polygon);
          let _that = this;
          polygon.on("click", (e) => {
            let point = this.isPoint(e);
            this.map.setCenter(point);

            // map.setZoom(15)
            const $dome = areaWindowInfo.call(_that, {
              baseInfo: item,
            });

            //鼠标点击marker弹出自定义的信息窗体
            let infoWindow = new AMap.InfoWindow({
              isCustom: true, //使用自定义窗体  windowInfo(e.target.orderno.deviceDataInfos)
              content: $dome,
              closeWhenClickMap: false,
              anchor: new AMap.Pixel(0, 0),
              offset: new AMap.Pixel(68, -65),
            });

            infoWindow.open(this.map, point);

            _that.infoWindow = infoWindow;
          });
        });
      },
      // 获取ip定位信息
      getIpPosition(AMap) {
        AMap.plugin("AMap.Geolocation", function () {
          new AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: true,
            // 设置定位超时时间，默认：无穷大
            timeout: 10000,
            // 定位按钮的停靠位置的偏移量
            offset: [10, 20],
            //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            zoomToAccuracy: true,
            //  定位按钮的排放位置,  RB表示右下
            position: "RB",
          });
        });
      },
      // 设置轨迹
      setPathSimp(info) {
        let AMap = this.AMap;
        let that = this;
        let { id, path } = info;
        let transformPath = [];
        if (path && path.length) {
          transformPath = path.map((item) => transform.gTw(item[0], item[1]));
        }

        if (this.simpInfo && this.simpInfo[id]) {
          that.map.remove(this.simpInfo[id]);
          delete this.simpInfo[id];
        }
        AMap.plugin("AMap.MoveAnimation", () => {
          //异步加载插件
          let lastIndex = transformPath.length - 1;
          var showIcon = new AMap.Icon({
            // 图标尺寸
            size: new AMap.Size(28, 28),
            // 图标的取图地址
            image: this.arrowIcon,
            // 图标所用图片大小
            imageSize: new AMap.Size(28, 28),
          });
          var delIcon = new AMap.Icon({
            // 图标尺寸
            size: new AMap.Size(28, 28),
            // 图标的取图地址
            image: this.delIcon,
            // 图标所用图片大小
            imageSize: new AMap.Size(28, 28),
          });

          // 绘制轨迹

          let polyline = new AMap.Polyline({
            map: this.map,
            path: transformPath,
            showDir: true,
            strokeColor: "#28F", //线颜色
            // strokeOpacity: 1,     //线透明度
            strokeWeight: 5, //线宽
            // strokeStyle: "solid"  //线样式
          });
          let passedPolyline = new AMap.Polyline({
            map: that.map,
            // path: lineArr,
            strokeColor: "#AF5", //线颜色
            // strokeOpacity: 1,     //线透明度
            strokeWeight: 5, //线宽
            // strokeStyle: "solid"  //线样式
          });

          let marker = new AMap.Marker({
            map: that.map,
            position: transformPath[0],
            // icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
            icon: showIcon,
            offset: new AMap.Pixel(-15, -13),
            // autoRotation: true,
            // angle:0,
          });
          let delMarker = new AMap.Marker({
            map: that.map,
            position: transformPath[lastIndex],
            // icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
            icon: delIcon,
            offset: new AMap.Pixel(-15, -13),
            // autoRotation: true,
            // angle:0,
          });
          if (!this.simpInfo) this.simpInfo = {};

          this.simpInfo[id] = [marker, passedPolyline, polyline];
          marker.isAnimel = true;
          marker.on("click", function (e) {
            if (e.target.isAnimel) {
              marker.moveAlong(transformPath, { duration: 3000, autoRotation: true });
            }
          });
          delMarker.on("click", function (e) {
            that.map.remove([marker, delMarker, passedPolyline, polyline]);
          });
          marker.on("moving", function (e) {
            passedPolyline.setPath(e.passedPath);
            // this.map.setCenter(e.target.getPosition(),true)
          });
          marker.on("movealong", function (e) {
            e.target.isAnimel = false;
            // marker.setIcon(delIcon)
            that.map.remove(marker);
          });
        });
      },
      // 获取基地
      async getEntBaseAreaInfo() {
        const res = await this.$api.EntBaseAreaInfo();
        if (res && res.length) {
          const allData = {
            baseId: "all",
            baseName: "全部基地",
          };
          res.unshift(allData);
          this.baseOption = res;
          this.getTraBaseAnimalInfo();
        } else {
          this.baseOption = [];
        }
      },
      // 顶部动物信息
      async getTraBaseAnimalInfo() {
        let baseId = this.baseId == "all" ? "" : this.baseId;
        const res = await this.$api.TraBaseAnimalInfo({ baseId });
        this.animalInfo = res || [];
      },
      // 获取围栏信息
      async getTraFenceDetailed() {
        let baseId = this.baseId == "all" ? "" : this.baseId;
        const res = await this.$api.TraFenceDetailed({ id: baseId });

        this.setPolygon(res);
      },
      // 获取动物点位列表
      // async getTraBaseAreaAnimalList() {
      //   let baseId = this.baseId == "all" ? "" : this.baseId;
      //   const res = await this.$api.TraBaseAreaAnimalList({ id: baseId });
      //   this.setMarket(res);
      // },
      // 查询告警信息
      async getTraWarningInfoPageList(isUpadte = false) {
        if (isUpadte){
          this.warnList = [];
          this.changLoading = true
        } 
        
        const res = await this.$api.TraWarningInfoPageList({ pageSize: 5, pageIndex: this.pageIndex, isView: false,warningStatus:this.activeWarn });

        if (res) {
          if (res?.list && res.list.length) this.warnList = this.warnList.concat(res.list);
          this.warnInfoNub = res?.totalCount || 0;
        }
        if (isUpadte){
         setTimeout(() => {this.changLoading = false},300)
          
        } 
      },
      // 已读
      async toRead(item) {
        if (item.warningStatus == 1) return;
        const res = await this.$api.EditTraWarningInfoStatus({ id: item.id });
        if (res) this.getTraWarningInfoPageList(true)
      },
      // 获取动物定位信息
      async toPostion(id) {
        const res = await this.$api.TraFenceAnimalPosition({ id });

        if (res) {
          this.showMapPosition(id, res);
        }
      },
      showMapPosition(id, resData) {

        this.map.setCenter([resData.lng, resData.lat]);
        if(this.delPositionMarker){
            this.map.remove([this.delPositionMarker, this.positionMarker]);
            this.positionMarker = null
            this.delPositionMarker = null
        }
        let AMap = this.AMap;
        let that = this
        var delIcon = new AMap.Icon({
            // 图标尺寸
            size: new AMap.Size(20, 20),
            // 图标的取图地址
            image: this.delIcon,
            // 图标所用图片大小
            imageSize: new AMap.Size(20, 20),
        });
        var showIcon = new AMap.Icon({
            // 图标尺寸
            size: new AMap.Size(48, 48),
            // 图标的取图地址
            image: this.positionIcon,
            // 图标所用图片大小
            imageSize: new AMap.Size(48, 48),
        });

        this.delPositionMarker = new AMap.Marker({
            map: this.map,
            position: [resData.lng, resData.lat],
            // icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
            icon: delIcon,
            offset: new AMap.Pixel(13, -18),
            // autoRotation: true,
            // angle:0,
        });
        this.positionMarker = new AMap.Marker({
            map: that.map,
            position: [resData.lng, resData.lat],
            // icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
            icon: showIcon,
            offset: new AMap.Pixel(0, 0),
            // autoRotation: true,
            // angle:0,
        });
        this.delPositionMarker.on("click",  (e)=> {
            this.map.remove([this.delPositionMarker, this.positionMarker]);
            this.positionMarker = null
            this.delPositionMarker = null
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  $warnc: #ff9000;
  .electronic_fence_box {
    height: 100%;
    .top_row_box {
      height: 70px;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left_box {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
      .ml-30 {
        margin-left: 30px;
      }
      .mr-60 {
        margin-right: 60px;
      }
      .mr-10 {
        margin-right: 10px;
      }
      .right_box {
        display: flex;
        align-items: center;
      }
    }
    .fence_content_box {
      height: calc(100% - 70px);
      display: flex;
      & > div {
        height: 100%;
      }
      .meun_search_box{
        width: 300px;
        padding: 0 20px 0 20px;
        box-sizing: border-box;
        & > div{
         
          height: 100%;
        }
      }
      .map_box {
        flex: 1;
        position: relative;
        .open_iocn_box {
          width: 30px;
          height: 30px;
          line-height: 30px;
          background-color: #15232d;
          border-radius: 15px;
          text-align: center;
          position: absolute;
          right: 20px;
          top: 20px;
          cursor: pointer;
          transform: width 0.5s linear;
          .iconfont {
            font-size: 18px;
            color: $warnc;
          }
        }
      }
      .warn_box {
        width: 260px;
        height: 100%;
        display: flex;
        flex-direction: column;
        // padding-bottom: 20px;
        box-sizing: border-box;
        .warn_title_box {
          display: flex;
          align-items: center;
          height: 100%;
          font-size: 18px;

          .iconfont {
            color: $warnc;
            font-size: 22px;
            margin-right: 10px;
          }
        }
        .warn_top_row {
          height: 50px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          border-bottom: 1px solid #e6e6e6;
          .update_icon {
            color: #fff;
            font-size: 22px;
            cursor: pointer;
          }
        }
        .pages_box {
          text-align: center;
        }
      }
      .warn_menu{
        height: 30px;
        margin: 10px 0 5px 0;
        font-size: 14px;
        display: flex;
        & > div {
           flex: 1;
           text-align: center;
        }
      }
      .active_warn{
         color: var(--el-color-primary);
         position: relative;
         &::after{
           content: '\e8bc';
           font-family: 'iconfont';
           font-size: 44px;
           position: absolute;
           left: 44px;
           bottom: -20px;
         }
      }
      
      .warn_list {
        //  flex: 1;
        //  min-height: 0;
        height: calc(100% - 115px);
        padding: 10px 20px;
        box-sizing: border-box;
        overflow-y: auto;
        .warn_item {
          width: 100%;
          border-radius: 10px;
          border: 1px solid #fff;
          background-color: rgba(#28353f, 0.5);
          position: relative;
          padding: 5px 5px 10px 5px;
          box-sizing: border-box;
          font-size: 14px;
          .point {
            width: 8px;
            height: 8px;
            background: #e60012;
            border-radius: 50%;
            position: absolute;
            right: 6px;
            top: 8px;
          }
          .info_box {
            .row_flex_box{
              padding: 0 5px;
              box-sizing: border-box;
              margin-top: 3px;
              display: flex;
              font-size: 12px;
              & > span:first-child {
                flex-shrink: 0;
              }
            }
            .row_info_box{
              padding: 0 5px;
              box-sizing: border-box;
              margin-top: 3px;
              font-size: 12px;
            }
            .time_box {
              text-align: right;
              color: #ccc;
              margin-top: 6px;
              margin-right: 5px;
            }
            .but_box {
              display: flex;
              justify-content: space-between;
              padding: 0 5px;
              box-sizing: border-box;
              .but {
                margin-top: 6px;
                width: 86px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                background: rgba(242, 245, 250, 0.1);
                border: 1px solid #f2f5fa;
                border-radius: 10px;
              }
              .active_but {
                background: rgba(255, 144, 0, 0.1);
                border: 1px solid #ff9000;
                color: $warnc;
              }
            }
            .floatRight{
              justify-content: flex-end;
            }
          }
        }
        .warn_item:not(:first-child) {
          margin-top: 10px;
        }
      }
      .colse_warn {
        width: 0px !important;
      }
    }
    .warnc {
      color: $warnc;
    }
    
  }
</style>
